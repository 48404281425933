AFRAME.registerComponent('umbra', {
    schema: {
        initialized: {default: false}
    },
    init: function() {
        var self = this;

        setTimeout(async function(){
            console.error("TEST");
            console.log(UmbraRuntime);
            var sceneEl = self.el.sceneEl;
            console.warn("loading umbra");
            
            var camera = document.querySelector("[camera]").getObject3D('camera');
            console.log(camera);
            camera.umbraQuality = 0.05;
    
            self.Umbra = await UmbraRuntime.initWithThreeJS(sceneEl.renderer, { wasmURL: 'assets/umbra.wasm' });
            console.info("umbra runtime loaded!");
            let umbraScene = self.Umbra.createScene('key=pubk-73215c82-34a0-4672-91f8-85ec076174bf&project=50705105&model=1042959879')
            umbraScene.scale.set(1.0, 1.0, 1.0)
            umbraScene.position.set(5400,-15,-5400);
            umbraScene.updateMatrix()
            umbraScene.wireframe = false;
            umbraScene.onConnected = () => {
                self.data.initialized = true;
                console.log(umbraScene.getBounds())
            }
            umbraScene.onConnectionError = (err) => {
                console.log(err)
            }
            sceneEl.object3D.add(umbraScene);
            console.log(sceneEl.object3D);
        },2000);
        
    },
    tick: function() {
        if(this.data.initialized){
            this.Umbra.update();
        }
        
    }
});